import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';

const PaymentModal = ({ poNumber, closeModal, submitPayment, bankAccounts, purchaseOrderDetails, stores, isCOGSChecked, selectedStore, setSelectedStore, fromCashRegister }) => {
    const formatDate = (date) => {
        return date.toISOString().split('T')[0];
    }

    const apiUrl = process.env.REACT_APP_BASE_URL;

    const [receivedDate, setReceivedDate] = useState(formatDate(new Date()));
    const [receiverNotes, setReceiverNotes] = useState('');
    const [paymentType, setPaymentType] = useState('');
    const [bankSplits, setBankSplits] = useState({});
    const [storeSplits, setStoreSplits] = useState({});
    const [amountPaid, setAmountPaid] = useState(0);
    const [paymentDate, setPaymentDate] = useState(formatDate(new Date()));
    const [paidBy, setPaidBy] = useState('');
    const [receivedItems, setReceivedItems] = useState([]);
    const [successMessage, setSuccessMessage] = useState('');
    const [validationMessage, setValidationMessage] = useState('');
    const [selectedStores, setSelectedStores] = useState([]);

    //const [bankAccount, setBankAccount] = useState('');

    const paymentOptions = [
        { value: '', label: 'Select Payment Type' },
        { value: 'Cash', label: 'Cash' },
        { value: 'Transfer', label: 'Bank Transfer' },
        { value: 'DebitCard', label: 'Debit Card' },
        { value: 'CreditCard', label: 'Credit Card' }
    ];

    useEffect(() => {
        if (fromCashRegister && selectedStore) {
            setPaymentType('Cash');
            const storeToUpdate = stores.find(store => store.value === selectedStore.value);
            setSelectedStore(storeToUpdate); // assuming selectedStore is the store passed from CashRegisterForm
        }
    }, [fromCashRegister, selectedStore, stores, setSelectedStore]);

    const calculateGrandTotal = () => {
        return receivedItems.reduce(
            (acc, item) => acc + (parseFloat(item.totalToBePaid) || 0),
            0
        ).toFixed(2); // Ensure consistent precision
    }

    const grandTotal = parseFloat(calculateGrandTotal());

    // Dynamically calculate amountPaid whenever storeSplits or bankSplits change
    
    const handleSplitChange = (type, id, value) => {
        const newValue = parseFloat(value) || 0;
    
        if (type === 'store') {
            const updatedStoreSplits = { ...storeSplits, [id]: newValue };
            setStoreSplits(updatedStoreSplits);
    
            // Calculate `amountPaid` synchronously
            const totalStoreSplit = Object.values(updatedStoreSplits).reduce(
                (sum, val) => sum + (parseFloat(val) || 0),
                0
            );
            const totalBankSplit = Object.values(bankSplits).reduce(
                (sum, val) => sum + (parseFloat(val) || 0),
                0
            );
            setAmountPaid(totalStoreSplit + totalBankSplit);
        } else if (type === 'account') {
            const updatedBankSplits = { ...bankSplits, [id]: newValue };
            setBankSplits(updatedBankSplits);
    
            // Calculate `amountPaid` synchronously
            const totalStoreSplit = Object.values(storeSplits).reduce(
                (sum, val) => sum + (parseFloat(val) || 0),
                0
            );
            const totalBankSplit = Object.values(updatedBankSplits).reduce(
                (sum, val) => sum + (parseFloat(val) || 0),
                0
            );
            setAmountPaid(totalStoreSplit + totalBankSplit);
        }
    
        // Perform validation immediately
        validateSplits();
    };

    const validateSplits = useCallback(() => {
        const toFixedTwo = (num) => parseFloat(num.toFixed(2));

        const totalStoreSplit = toFixedTwo(
            Object.values(storeSplits).reduce((sum, val) => sum + (parseFloat(val) || 0), 0)
        )
        console.log('Total Store Split:', totalStoreSplit);

        const totalBankSplit = toFixedTwo(
            Object.values(bankSplits).reduce((sum, val) => sum + (parseFloat(val) || 0), 0)
        );
        console.log('Total Bank Split:', totalBankSplit);

        const combinedTotal = toFixedTwo(totalStoreSplit + totalBankSplit);
        console.log('Combined Total Split:', combinedTotal);
    
        // Use a fixed grandTotal for comparison
        const fixedGrandTotal = toFixedTwo(grandTotal);
        console.log('Fixed Grand Total:', fixedGrandTotal);

        if (combinedTotal > fixedGrandTotal) {
            setValidationMessage('Total split exceeds the grand total.');
            console.log('Validation Message Set: Total split exceeds the grand total.');
            return false;
        }
        if (combinedTotal !== fixedGrandTotal) {
            setValidationMessage('Total split does not match the payment amount.');
            console.log('Validation Message Set: Total split does not match the payment amount.');
            return false;
        }

        setValidationMessage('');
        return true;
    }, [storeSplits, bankSplits, grandTotal]);

    useEffect(() => {
        validateSplits();
    }, [storeSplits, bankSplits, grandTotal, validateSplits]);

    useEffect(() => {
        console.log("PO Number:", poNumber);
        console.log("Purchase Order Details:", purchaseOrderDetails);
    }, [poNumber, purchaseOrderDetails]);    

    useEffect(() => {
        const fetchAndSetReceivedItems = async () => {
            if (purchaseOrderDetails && purchaseOrderDetails.items) {
                const fetchInventoryItemDetails = async (inventoryItemId) => {
                    try {
                        const response = await axios.get(`${apiUrl}inventory/inventory/${inventoryItemId}/`);
                        return response.data;
                    } catch (error) {
                        console.error('Error fetching inventory item details:', error);
                        return null;
                    }
                };
    
                const fetchedItems = await Promise.all(
                    purchaseOrderDetails.items.map(async (item) => {
                        if (!item.is_paid) {
                            const inventoryDetails = await fetchInventoryItemDetails(item.inventory_item);
                            return {
                                ...item,
                                inventory_item: {
                                    id: inventoryDetails.id,
                                    productName: inventoryDetails.productName,
                                    expense_subcategory_id: inventoryDetails.expense_subcategory,
                                },
                                quantityReceived: 0,
                                totalToBePaid: 0,
                            };
                        }
                    })
                );
                setReceivedItems(fetchedItems.filter((item) => item != null));
            }
        };
    
        fetchAndSetReceivedItems();
    }, [purchaseOrderDetails, apiUrl]);

    const handleQuantityReceivedChange = (index, quantityReceived) => {
        const updateReceivedItems = receivedItems.map((item, idx) => {
            if (idx === index) {
                return {
                    ...item,
                    quantityReceived,
                    totalToBePaid: item.unit_cost * quantityReceived,
                };
            }
            return item;
        });
        setReceivedItems(updateReceivedItems);
    };
    //console.log("selected Store : ", selectedStore.value)
    const handleStoreCheckboxChange = (storeId, isChecked) => {
        setSelectedStores((prev) => {
            if (isChecked) {
                // Add the store to the selectedStores list
                if (!prev.find((store) => store.storeId === storeId)) {
                    return [...prev, { storeId, splitValue: '' }];
                }
                return prev;
            } else {
                // Remove the store from the selectedStores list
                return prev.filter((store) => store.storeId !== storeId);
            }
        });
    };

    const handleSplitInputChange = (storeId, splitValue) => {
        setSelectedStores((prev) =>
          prev.map((store) =>
            store.storeId === storeId
              ? { ...store, splitValue: parseFloat(splitValue) || '' }
              : store
          )
        );
    };

    const handleSubmit = () => {
        if (!validateSplits()) return;

        const splits = paymentType === 'Cash' ? storeSplits : bankSplits;
        const formattedSplits = Object.entries(splits).map(([id, value]) => ({
            id,
            split_amount: parseFloat(value.toFixed(2)),
        }));

        // Construct payment and receiving information payload
        const paymentAndReceivingInfo = {
            paymentType, 
            bankSplits: paymentType !== 'Cash' ? formattedSplits : [],
            storeSplits: selectedStores.map((store) => ({
                id: store.storeId,
                split_amount: parseFloat(store.splitValue.toFixed(2)), 
            })),
            selectedStores: selectedStores.map((store) => ({
                ...store,
                splitValue: parseFloat(store.splitValue.toFixed(2)), 
            })),
            amountPaid: grandTotal.toFixed(2), 
            paymentDate,
            paidBy,
            receivedDate, 
            receiverNotes,
            store_id: selectedStore ? selectedStore.value : '',
            is_cogs: isCOGSChecked,
            items: receivedItems.map(item => ({
                inventory_item: item.inventory_item.id,
                quantityReceived: item.quantityReceived,
                expense_subcategory: item.inventory_item.expense_subcategory_id,
                isPaid: item.quantityReceived > 0,
                unit_cost: item.unit_cost
            }))
            // Include other necessary data such as quantities received for each item
        };
        submitPayment(poNumber, paymentAndReceivingInfo).then(() => {
            setSuccessMessage('Payment saved');
            setTimeout(() => {
                setSuccessMessage('');
                closeModal();
            }, 3000); // Close modal after 3 seconds

            window.location.href = `/purchase-order`;
        })
        .catch((error) => {
            if (error && error.response) {
                console.error('Error during payment submission: ', error.response.data);
            } else {
                console.error('Error during payment submission: ', error);
            }
        });
    };

    return (
        <div className="payment-modal">
            <span className="payment-modal-close" onClick={closeModal}>&times;</span>
            <h3>Payment and Receiving Details</h3>
            <div className='mt-4'>
                <label>Received Date:</label>
                <input type="date" value={receivedDate} onChange={(e) => setReceivedDate(e.target.value)} />
            </div>
            {successMessage && <div className="success-message">{successMessage}</div>}
            {purchaseOrderDetails && purchaseOrderDetails.items && (
                <table>
                    <thead>
                        <tr>
                            <th>Name of Product</th>
                            <th>Quantity</th>
                            <th>Unit Cost</th>
                            <th>Total Cost</th>
                            <th>Quantity Received</th>
                            <th>Total to be Paid</th>
                        </tr>
                    </thead>
                    <tbody>
                        {receivedItems.map((item, index) => (
                            <tr key={index}>
                                <td>{item.inventory_item.productName}</td>
                                <td>{item.quantity_ordered}</td>
                                <td>{item.unit_cost}</td>
                                <td>{item.total_cost}</td>
                                <td>
                                    <input 
                                        type="number" 
                                        min="0" 
                                        value={item.quantityReceived}
                                        onChange={(e) => handleQuantityReceivedChange(index, Number(e.target.value))}
                                    />
                                </td>
                                <td>{item.totalToBePaid.toFixed(2)}</td>
                            </tr>
                        ))}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan="5">Grand Total to be Paid</td>
                            <td>{grandTotal.toFixed(2)}</td>
                        </tr>                       
                    </tfoot>
                </table>
            )}
            <div className="store-selection">
                <label>Select the store making this payment:</label>
                <div>
                    {stores.map((store) => (
                        <div key={store.value}>
                            <input
                                type="checkbox"
                                id={`store-${store.value}`}
                                value={store.value}
                                checked={selectedStores.some((s) => s.storeId === store.value)}
                                onChange={(e) => handleStoreCheckboxChange(store.value, e.target.checked)}
                            />
                            <label htmlFor={`store-${store.value}`}>{store.label}</label>
                            <input
                                type="number"
                                className="store-split-input"
                                min="0"
                                placeholder="Allocation"
                                value={
                                    selectedStores.find((s) => s.storeId === store.value)?.splitValue || ''
                                }
                                onChange={(e) => handleSplitInputChange(store.value, e.target.value)}
                                disabled={!selectedStores.find((s) => s.storeId === store.value)}
                            />
                        </div>
                    ))}
                </div>
                {selectedStores.length === 0 && (
                    <div className="validation-message">
                    Please select a store making the payment.
                    </div>
                )}

                {/* Total Allocated Amount */}
                {selectedStores.length > 0 && (
                    <div
                    className={`allocated-amount ${
                        selectedStores.reduce(
                        (total, store) => total + (parseFloat(store.splitValue) || 0),
                        0
                        ) === grandTotal
                        ? 'match'
                        : 'mismatch'
                    }`}
                    >
                    <strong>Total Stores Allocated Amount:</strong> 
                    {` ${selectedStores
                        .reduce((total, store) => total + (parseFloat(store.splitValue) || 0), 0)
                        .toFixed(2)}`}
                    </div>
                )}
            </div>
            {/* Payment Type */}
            <div className="mb-2 mt-2">
                <label>Payment Type:</label>
                <select value={paymentType} onChange={(e) => setPaymentType(e.target.value)}>
                {paymentOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                    {option.label}
                    </option>
                ))}
                </select>
            </div>
            {paymentType === 'Cash' && (
                <div>
                    <label>Store Splits:</label>
                    <table>
                        <tbody>
                            {stores.map((store) => (
                                <tr key={store.value}>
                                    <td>{store.label}</td>
                                    <td>
                                        <input
                                        type="number"
                                        value={storeSplits[store.value] || ''}
                                        onChange={(e) => handleSplitChange('store', store.value, e.target.value)} 
                                        style={{ width: '100px' }}/>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
            {['Transfer', 'DebitCard', 'CreditCard'].includes(paymentType) && (
                <div>
                    <label>Bank Account Splits:</label>
                    <table>
                        <thead>
                        <tr>
                            <th>Bank Account</th>
                            <th>Allocation</th>
                        </tr>
                        </thead>
                        <tbody>
                        {bankAccounts.map((account) => (
                            <tr key={account.id}>
                            <td>
                                {account.bank_name} - {account.account_number}
                            </td>
                            <td>
                                <input
                                type="number"
                                value={bankSplits[account.id] || ''}
                                onChange={(e) => handleSplitChange('account', account.id, e.target.value)} 
                                style={{ width: '100px' }}/>
                            </td>
                            </tr>
                        ))}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td><strong>Total Amount Paid:</strong></td>
                                <td>
                                    <input
                                        type='number'
                                        value={amountPaid.toFixed(2)}
                                        readOnly
                                        className='amount-paid-input'
                                    />
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            )}
            {validationMessage && 
                <div className="validation-message" style={{ display: 'block', color: 'red' }}>
                    {validationMessage}
                </div>
            }
            <div className='mt-2'>
                <label>Receiver's Notes:</label>
                <textarea value={receiverNotes} onChange={(e) => setReceiverNotes(e.target.value)} />
            </div>
            <div>
                <label>Payment Date:</label>
                <input type="date" value={paymentDate} onChange={(e) => setPaymentDate(e.target.value)} />
            </div>
            <div className='mt-2 mb-2'>
                <label>Paid By:</label>
                <input type="text" value={paidBy} onChange={(e) => setPaidBy(e.target.value)} />
            </div>
            <button onClick={handleSubmit} className='mr-2'>Submit Payment</button>
            <button onClick={closeModal}>Close</button>
        </div>
    );
};

export default PaymentModal;
