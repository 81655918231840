import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import Header from "../DashboardHeader";
import Cards from "./FinancialComponents/Cards";
import CashBalanceChart from "./FinancialComponents/CashBalance";
import RevenueCogs from "./FinancialComponents/RevenueCogs";
import IncomeSatement from "./FinancialComponents/IncomeStatement";
import { useSelector } from "react-redux";

const Financial = () => {
  const {
    userLogin: { userInfo },
  } = useSelector((state) => state);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [salesRecords, setSalesRecords] = useState(null);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");

  // Define a constant for the API base URL.
  const API_URL = process.env.REACT_APP_BASE_URL;

  // Fetch shop users (this could be used by other components too).
  const fetchUsers = useCallback(async () => {
    try {
      const { data } = await axios.get(`${API_URL}api/ShowShopView/`, {
        headers: { Authorization: `Bearer ${userInfo.token}` },
      });
      setUsers(data);
    } catch (error) {
      console.error("Error fetching users:", error);
      // Optionally, set an error state to display a friendly message.
    }
  }, [API_URL, userInfo.token]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const handleFetchFinancialData = async (e) => {
    e.preventDefault();
    const filters = {};
    if (startDate) filters.start_date = startDate;
    if (endDate) filters.end_date = endDate;
    if (selectedUser && selectedUser !== "all") filters.shop = selectedUser;

    if (Object.keys(filters).length === 0) {
      alert("Please select at least one filter.");
      return;
    }

    try {
      const { data } = await axios.post(
        `${API_URL}inventory/sales-report/`,
        filters
      );
      setSalesRecords(data.sale_records);
      console.log("Sales report response:", data);
    } catch (error) {
      console.error("Error fetching sales report:", error);
    }
  };

  return (
    <div className="row">
      <Header />
      <div className="container">
        {/* Row 1: Full width Cards */}
        <div className="row mb-4">
          <div className="col-md-12">
            <Cards />
          </div>
        </div>

        {/* Row 2: Left: CashBalanceChart (2/3) | Right: RevenueCogs (1/3) */}
        <div className="row mx-4 mb-4">
          <div className="col-md-8">
            <CashBalanceChart />
          </div>
          <div className="col-md-4">
            <RevenueCogs />
          </div>
        </div>

        <div className="row mx-4 mb-4">
          <div className="col-md-12">
            <div className="text-center p-4 border rounded">
              <IncomeSatement />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Financial;
