import React from "react";
import { Table } from "react-bootstrap";

const ExpenseDetail = ({ expenseDetail }) => {
  if (!expenseDetail || !expenseDetail.details || expenseDetail.details.length === 0) return <div>No data available</div>;

  // Function to format currency in Mexican Pesos
  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(amount);
  };

  const renderPurchaseOrderDetails = (purchaseOrderDetails) => {
    if (!purchaseOrderDetails || purchaseOrderDetails.length === 0) return null;
    const poNumber = purchaseOrderDetails[0]?.purchase_order__po_number || "N/A";

    return (
        <div>
            <h5>Purchase Order Items (PO Number: {poNumber})</h5>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Product Name</th>
                        <th>Quantity</th>
                        <th>Unit Cost</th>
                        <th>Total Cost</th>
                    </tr>
                </thead>
                <tbody>
                    {purchaseOrderDetails.map((item, index) => (
                        <tr key={index}>
                            <td>{item.inventory_item__productName}</td>
                            <td>{item.quantity_ordered}</td>
                            <td>{formatCurrency(item.unit_cost)}</td>
                            <td>{formatCurrency(item.total_cost)}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );
  };

  const renderTableHeaders = (isPurchaseOrder) => {
    return (
        <thead>
            <tr>
                <th>Date</th>
                <th>Amount</th>
                {!isPurchaseOrder && <th>Category</th>}
                {!isPurchaseOrder && <th>Subcategory</th>}
                <th>Is COGS</th>
                <th>Is Fixed</th>
                <th>Payment Method</th>
            </tr>
        </thead>
    );
  };

  const renderExpenseRow = (expense) => {
    const isPurchaseOrder = !!expense.purchase_order_details;

    return (
        <>
            <tr key={expense.due_date}>
                <td>{expense.due_date}</td>
                <td>{formatCurrency(expense.amount)}</td>
                {!isPurchaseOrder && <td>{expense.subcategory__category__name || "N/A"}</td>}
                {!isPurchaseOrder && <td>{expense.subcategory__name || "N/A"}</td>}
                <td>{expense.is_cogs ? "Yes" : "No"}</td>
                <td>{expense.is_fixed ? "Yes" : "No"}</td>
                <td>{expense.payment_method}</td>
            </tr>
            {isPurchaseOrder && renderPurchaseOrderDetails(expense.purchase_order_details)}
        </>
    );
  };

  return (
    <div>
        <h4>Expense Detail</h4>
        <Table striped bordered hover>
            {renderTableHeaders(!!expenseDetail.details.find((e) => e.purchase_order_details))}
            <tbody>
                {expenseDetail.details.map((expense, index) => renderExpenseRow(expense))}
            </tbody>
        </Table>
    </div>
  );
};

export default ExpenseDetail;