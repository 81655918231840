import React, { useEffect, useState } from 'react';
import ContactList from './ContactList';
import ChatConversation from './ChatConversation';
import Sidebar from "../Design/TodayBookingSidebar.js";

const MessageScreen = () => {
    const [selectedContact, setSelectedContact] = useState(null);

    const handleSelectContact = (contact) => {
        console.log("Selected contact:", contact);
        setSelectedContact(contact);
    };

    const handleBackToList = () => {
        console.log("Returning to contact list");
        setSelectedContact(null);
    };

    return(
        <div className="row">
            <div className="col-md-3" style={{paddingRight: "0px"}}>
                <Sidebar />
            </div>
            <div className="col-md-9" style={{ paddingLeft: "0px" }}>
                <div className="messaging-container">
                    {selectedContact ? (
                        <ChatConversation contact={selectedContact} onBack={handleBackToList} />
                    ) : (
                        <ContactList onSelectContact={handleSelectContact} />
                    )}
                </div>
            </div>
        </div>
    );
};

export default MessageScreen;