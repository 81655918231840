import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './MessageScreen.css';

const ChatConversation = ({ contact, onBack }) => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);

  const openImageModal = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  const closeImageModal = () => {
    setSelectedImage(null);
  };

  const fetchMessages = async () => {
    console.log("Fetching messages for:", contact.id);  // ✅ Debug log

    try {
        console.log("📩 Fetching messages for:", contact.id); //
        const response = await axios.get(`/messaging/messages/${contact.id}`);
        console.log("API Response:", response.data);  // ✅ Log response

        if (!Array.isArray(response.data)) {
          throw new Error("Invalid response format");
        }

        const updatedMessages = response.data.map(msg => ({
            ...msg,
            media_type: msg.media_type || "text/plain",  // ✅ Ensure media_type is always defined
        }));

        console.log("Updated Messages State:", updatedMessages);
        setMessages(updatedMessages);
    } catch (error) {
        console.error("Error fetching messages:", error);
    }
  };

  const markMessagesAsRead = async () => {
    try {
      await axios.post(`/messaging/mark-as-read/${contact.id}/`);
    } catch (error) {
      console.error("Error marking messages as read:", error)
    }
  }

  useEffect(() => {
    console.log("🛠️ useEffect triggered for contact:", contact.id);
    fetchMessages();
    markMessagesAsRead(); // Mark messages as read when the conversation is opened

    const interval = setInterval(() => {
      console.log("🔄 Fetching messages again...");
      fetchMessages();
    }, 10000);

    return () => {
      console.log("🛑 Cleaning up interval...");
      clearInterval(interval);
    };
  }, [contact.id]);

  const formatPhoneNumber = (number) => {
    // Remove all non-numeric characters and leading '+'
    const cleaned = number.replace(/\D/g, '');
    // Check if the number starts with '521', then reformat it
    if (cleaned.startsWith('521')) {
      return `+52${cleaned.slice(3)}`;
    } else if (cleaned.startsWith('52')) {
      return `+52${cleaned.slice(2)}`;
    } else {
      return `+52${cleaned}`;  // Add '+52' prefix if missing
    }
  };

  const sendMessage = () => {
    const formattedNumber = formatPhoneNumber(contact.id); // Format the number here

    console.log("Sending message to:", formattedNumber);  // Log the contact ID
    console.log("Message content:", newMessage);     // Log the message content

    axios.post('/messaging/send-message/', 
      new URLSearchParams({
        to: formattedNumber, 
        body: newMessage
      }).toString(),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
    )
    .then((response) => {
      const outboundMessage = {
        sender: 'Me',
        message: newMessage,
        profile_name: 'Me',
        timestamp: new Date().toISOString(),
        direction: 'outbound'
      };
        setMessages([...messages, outboundMessage]);
        setNewMessage('');
    })
    .catch((error) => {
      console.error("Error sending message:", error);
    });
  };

  return (
    <div className="chat-conversation">
      <button onClick={onBack} className="back-button">Back</button>
      <h2>{contact.name}</h2>
      <div className="messages">
        {messages.map((msg, index) => (
          <div key={index} className={`message ${msg.direction}`}>
            {msg.message.startsWith("http") ? (
              msg.media_type && msg.media_type.includes("audio") ? ( // ✅ Check if it's an audio file
                <audio controls>
                  <source src={msg.message} type="audio/mpeg" />
                  Your browser does not support the audio element.
                </audio>
              ) : (
                <img
                  src={msg.message}
                  alt="Received Media"
                  className="chat-image"
                  onClick={() => openImageModal(msg.message)}
                />
              ) 
            ) : (
              <span>{msg.message}</span>
            )}
          </div>
        ))}
      </div>
      
      {/* Image Modal for Full-Size Display */}
      {selectedImage && (
        <div className="modal-overlay" onClick={closeImageModal}>
          <div className="modal-content">
            <img src={selectedImage} alt="Full View" className="full-image" />
          </div>
        </div>
      )}
      
      <div className="input-bar">
        <button className="attach-btn">📎</button>
        <input
          type="text"
          placeholder="Type a message"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
        />
        <button onClick={sendMessage} className="send-btn">Send</button>
      </div>
    </div>
  );
};

export default ChatConversation;
