import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './MessageScreen.css';

const ContactList = ({ onSelectContact }) => {
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const [contacts, setContacts] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1); 

  const fetchContacts = async (pageNumber) => {
    
    try {
      const response = await axios.get(`${apiUrl}messaging/contacts/?page=${pageNumber}`);

      setContacts(response.data.contacts);
      setTotalPages(response.data.total_pages || 1);

    } catch(error) {
      console.error('Error fetching contacts:', error);
    }
  }

  useEffect(() => {
    setContacts([]);
    fetchContacts(page);  // Load only the current page
    
    const interval = setInterval(() => fetchContacts(page), 10000); // Auto refresh
    return () => clearInterval(interval);
  }, [page]);

  return (
    <div className="contact-list-container">
      <div className="contact-list">
        {contacts.map(contact => (
          <div key={contact.id} className="contact-item" onClick={() => onSelectContact(contact)}>
            <div className="contact-info">
              <h4>{contact.name}</h4>
              <p>{contact.lastMessage.length > 100 ? contact.lastMessage.slice(0, 100) + '...' : contact.lastMessage}</p>
            </div>
            {contact.unreadCount > 0 && <div className="unread-badge">{contact.unreadCount}</div>}
          </div>
        ))}
      </div>
      {/* Pagination Controls */}
      <div className="pagination-controls">
        <button 
          onClick={() => setPage(prev => Math.max(prev - 1, 1))}
          disabled={page === 1}
        >
          Prev
        </button>
        
        <span>Page {page} of {totalPages || 1}</span>
        
        <button 
          onClick={() => setPage(page + 1)} 
          disabled={page >= totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default ContactList;

