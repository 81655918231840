import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import "./ExpenseForm.css";
import { getCookie, fetchCSRFToken } from "../../utils/utils";
import Sidebar from "../Design/TodayBookingSidebar.js";


const ExpenseForm = () => {
  const apiUrl = process.env.REACT_APP_BASE_URL;

  const csrfToken = getCookie("csrftoken");
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [subcategories, setSubcategories] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [amount, setAmount] = useState("");
  const [dueDate, setDueDate] = useState(new Date());
  const [isFixed, setIsFixed] = useState(true);
  const [isCOGS, setIsCOGS] = useState(false);
  const [stores, setStores] = useState([]);
  const [selectedStore, setSelectedStore] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [bankAccounts, setBankAccounts] = useState([]);
  const [bankAccountSplits, setBankAccountSplits] = useState([]);

  useEffect(() => {
    if (!getCookie("csrftoken")) {
      fetchCSRFToken();
    }
  }, []);

  useEffect(() => {
    axios
      .get(`${apiUrl}inventory/expenses/categories-filter/`)
      .then((response) => {
        setCategories(response.data);
      });
    axios.get(`${apiUrl}inventory/stores/`).then((response) => {
      setStores(response.data);
    });
    axios.get(`${apiUrl}inventory/accounts/bank/`).then((response) => {
      setBankAccounts(response.data);
    });
  }, [apiUrl]);

  useEffect(() => {
    // Fetch subcategories based on selected category
    if (selectedCategory) {
      axios
        .get(
          `${apiUrl}inventory/expenses/subcategories-all/?category=${selectedCategory}`
        )
        .then((response) => {
          setSubcategories(response.data);
        });
    } else {
      setSubcategories([]); // Reset subcategories when no category is selected
    }
  }, [selectedCategory, apiUrl]);

  const resetForm = () => {
    setSelectedCategory("");
    setSelectedSubCategory("");
    setSelectedStore("");
    setAmount("");
    setDueDate(new Date());
    setIsFixed(false);
    setIsCOGS(false);
    setPaymentMethod("");
    setBankAccountSplits([]);
    setStores((prevStores) =>
      prevStores.map((store) => ({
        ...store,
        splitAmount: 0, // Clear split amounts for stores
      }))
    );
  };

  const handleSelectionChange = (type, id, isChecked) => {
    const currentlySelected = stores.filter(store => store.splitAmount > 0);
    const newCount = isChecked ? currentlySelected.length + 1 : currentlySelected.length - 1;
    const newDefault = newCount > 0 ? parseFloat(amount) / newCount : 0;

    setStores((prevStores) =>
      prevStores.map(store => {
        if (store.id === id ) {
          return { ...store, splitAmount: isChecked ? newDefault : 0 };
        } else if (store.splitAmount > 0) {
          return { ...store, splitAmount: newDefault };
        } else {
          return store;
        }
      })
    ); 
  };

  const handleSplitChange = (type, id, value) => {
    const newValue = parseFloat(value) || 0;

    if (type === "store") {
      setStores((prevStores) =>
        prevStores.map((store) =>
          store.id === id ? { ...store, splitAmount: parseFloat(value) || 0 } : store
        )
      );
    } else if (type === "account") {
      setBankAccountSplits((prevSplits) =>
        prevSplits.map((account) =>
          account.id === id ? { ...account, splitAmount: newValue } : account
        )
      );
    }
  };

  const handleBankAccountSelection = (id, isChecked) => {
    if (isChecked) {
      const account = bankAccounts.find((acc) => acc.id === id);
      setBankAccountSplits((prevSplits) => [...prevSplits, { ...account, splitAmount: 0 }]);
    } else {
      setBankAccountSplits((prevSplits) =>
        prevSplits.filter((account) => account.id !== id)
      );
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!selectedStore || !selectedCategory || !amount) {
      console.error("Please select all required options.");
      return; // Prevents the form from being submitted
    }

    // For validation, use different totals based on the payment method.
    let totalSplitAmount = 0;
    if (paymentMethod === "cash") {
      totalSplitAmount = stores
        .filter(store => store.splitAmount > 0)
        .reduce((sum, store) => sum + (parseFloat(store.splitAmount) || 0), 0);
    } else {
      totalSplitAmount = bankAccountSplits
        .filter(split => split.splitAmount > 0)
        .reduce((sum, split) => sum + (parseFloat(split.splitAmount) || 0), 0);
    }

    // Ensure total split amount matches the total expense amount
    if (totalSplitAmount !== parseFloat(amount)) {
      console.error(
        `Total split amount (${totalSplitAmount}) must equal the expense amount (${amount}).`
      );
      return;
    }

    if (!csrfToken) {
        console.error("CSRF token is missing, cannot proceed.");
        return;
    }

    const expenseData = {
      store: selectedStore,
      subcategory_id: selectedSubCategory,
      category: selectedCategory,
      amount: parseFloat(amount) || 0,
      due_date: moment(dueDate).format("YYYY-MM-DDTHH:mm:ss"),
      payment_method: paymentMethod,
      is_cogs: isCOGS,
      is_fixed: isFixed,
      store_splits: stores
        .filter((store) => store.splitAmount > 0)
        .map(({ id, splitAmount }) => ({
          id,
          split_amount: parseFloat(splitAmount) || 0,
        })),
      account_splits: paymentMethod !== "cash" 
        ? bankAccountSplits.map(({ id, splitAmount }) => ({
            bank_account: id,
            split_amount: parseFloat(splitAmount) || 0,
          }))
        : [],
    };

    try {
      await axios.post(`${apiUrl}inventory/expenses/`, expenseData, {
        headers: {
          "X-CSRFToken": csrfToken,  // ✅ Use the fetched CSRF token
          "Content-Type": "application/json"
        },
        withCredentials: true,
      });
      setSuccessMessage("Your data has been saved.");
      setTimeout(() => {
        setSuccessMessage("");
        resetForm();
      }, 3000);
    } catch (error) {
      console.error("Error submitting the form", error.response.data);
    }
  };

  return (
    <div className="row">
      <div className="col-md-3" style={{ paddingRight: "0px" }}>
        <Sidebar />
      </div>
      <div className="col-md-9" style={{ paddingLeft: "0px" }}>
        <div className="expense-form-container">
          <form onSubmit={handleSubmit}>
            <h2>Record Expense</h2>

            {/* Real-time Summary */}
            <div className="form-section">
              <div className="section-title">Summary</div>
              <ul>
                <li>
                  <strong>Selected Store:</strong> {selectedStore || "None"}
                </li>
                <li>
                  <strong>Total Split Amount:</strong> $
                  {bankAccountSplits.reduce((total, split) => total + (split.splitAmount || 0), 0)}
                </li>
                {paymentMethod && (
                  <li>
                    <strong>Payment Method:</strong> {paymentMethod.replace("_", " ")}
                  </li>
                )}
              </ul>
            </div>

            <div className="form-section">
              <div className="section-title">Expense Details</div>

              <div className="form-row">
                <div>
                  <label htmlFor="dueDate">Payment Date:</label>
                  <input
                    type="date"
                    className="form-control"
                    value={moment(dueDate).format("YYYY-MM-DD")}
                    onChange={(event) => setDueDate(event.target.value)}
                  />
                </div>
              </div>
              <div className="form-row">
                <div>
                  <label htmlFor="category">
                    Category <span className="tooltip" title="Choose the main category for the expense.">?</span>
                  </label>
                  <select
                    id="category"
                    value={selectedCategory}
                    onChange={(e) => setSelectedCategory(e.target.value)}
                  >
                    <option value="" disabled>
                      Select a category
                    </option>
                    {categories.map((category) => (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <label htmlFor="subcategory">
                    SubCategory <span className="tooltip" title="Choose a specific subcategory.">?</span>
                  </label>
                  <select
                    id="subcategory"
                    value={selectedSubCategory}
                    onChange={(e) => setSelectedSubCategory(e.target.value)}
                  >
                    <option value="" disabled>
                      Select a subcategory
                    </option>
                    {subcategories.map((sub) => (
                      <option key={sub.id} value={sub.id}>
                        {sub.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <label htmlFor="amount">Amount:</label>
                  <input
                    type="number"
                    id="amount"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="form-section">
              <div className="section-title">Payment Information</div>
              <label htmlFor="store">Store:</label>
              <select
                id="store"
                value={selectedStore}
                onChange={(e) => setSelectedStore(e.target.value)}
              >
                <option value="" disabled>
                  Select a store
                </option>
                {stores.map((store) => (
                  <option key={store.id} value={store.id} className="text-dark">
                    {store.ShopName}
                  </option>
                ))}
              </select>

              <label htmlFor="paymentMethod">
                Payment Method{" "}
                <span className="tooltip" title="Select how the payment was made (e.g., cash, card).">?</span>
              </label>
              <select
                name="paymentMethod"
                value={paymentMethod} 
                onChange={(e) => setPaymentMethod(e.target.value)} 
              >
                <option value="">Select payment method</option>
                <option value="cash">Cash</option>
                <option value="debit_card">Debit Card</option>
                <option value="credit_card">Credit Card</option>
                <option value="transfer">Transfer</option>
              </select>

              <label>Stores:</label>
              {stores.map((store) => (
                <div key={store.id} className="checkbox-group">
                  <input
                    type="checkbox"
                    id={`store-${store.id}`}
                    checked={store.splitAmount > 0} 
                    onChange={(e) => handleSelectionChange("store", store.id, e.target.checked)}
                  />
                  <label htmlFor={`store-${store.id}`}>{store.ShopName}</label>
                  {store.splitAmount > 0 && (
                    <input
                      type="number"
                      value={store.splitAmount}
                      onChange={(e) => handleSplitChange("store", store.id, e.target.value)}
                    />
                  )}
                </div>
              ))}
              {paymentMethod !== "cash" && (
                <>
                  <label>Bank Accounts:</label>
                  {bankAccounts.map((account) => (
                    <div key={account.id} className="checkbox-group">
                      <input
                        type="checkbox"
                        id={`account-${account.id}`}
                        onChange={(e) =>
                          handleBankAccountSelection(account.id, e.target.checked)
                        }
                      />
                      <label htmlFor={`account-${account.id}`}>
                        {account.bank_name} - {account.account_number}
                      </label>
                      {bankAccountSplits.some((split) => split.id === account.id) && (
                        <input
                          type="number"
                          value={
                            bankAccountSplits.find((split) => split.id === account.id)?.splitAmount || 0
                          }
                          onChange={(e) =>
                            handleSplitChange("account", account.id, e.target.value) // Ensure "account" is passed as type
                          }
                        />
                      )}
                    </div>
                  ))}
                </>
              )}
            </div>
            <div className="form-section">
              
              <div className="section-heading">Expense Type</div>
              <div className="form-row">
                <div>
                  <label htmlFor="fixed">Fixed:</label>
                  <input
                    type="checkbox"
                    id="fixed"
                    checked={isFixed}
                    onChange={() => setIsFixed(!isFixed)}
                  />
                </div>
                <div>
                  <label htmlFor="cogs">COGS:</label>
                  <input
                    type="checkbox"
                    id="cogs"
                    checked={isCOGS}
                    onChange={() => setIsCOGS(!isCOGS)}
                  />
                </div>
              </div>
            </div>

            <button type="submit">Submit</button>
            {successMessage && (
              <div className="success-message">{successMessage}</div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default ExpenseForm;
