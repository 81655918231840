import React, { useState, useEffect } from 'react';
import axios from 'axios';

const ServicesForm = ({ service, onServiceSaved }) => {
    const apiUrl = process.env.REACT_APP_BASE_URL;

    const [serviceName, setServiceName] = useState('');
    const [serviceCategory, setServiceCategory] = useState('');
    const [servicePrice, setServicePrice] = useState('');

    // Update form fields when the service prop changes
    useEffect(() => {
        if (service) {
            setServiceName(service.name);
            setServiceCategory(service.category);
            setServicePrice(service.price);
        } else {
            setServiceName("");
            setServiceCategory("");
            setServicePrice("");
        }
    }, [apiUrl, service ? service.id : null]);

    const handleSubmit = (event) => {
        event.preventDefault();
    
        const payload = {
          name: serviceName,
          category: serviceCategory,
          price: servicePrice,
        };
    
        if (service) {
          // Edit mode: update the service using axios.put
          axios
            .put(`${apiUrl}inventory/inventory-services/${service.id}/`, payload)
            .then((response) => {
              onServiceSaved(response.data);
            })
            .catch((error) => console.error("Error updating service", error));
        } else {
          // Add mode: create a new service using axios.post
          axios
            .post(`${apiUrl}inventory/inventory-services/`, payload)
            .then((response) => {
              onServiceSaved(response.data);
            })
            .catch((error) => console.error("Error adding service", error));
        }
      };    

    return (
        <div className="row">
            <div className="col-md-12" style={{ paddingLeft: "0px" }}>
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-3"></div>
                        <div className="col-6">
                            <div className="row mb-4 mt-4">
                                <input
                                    type="text"
                                    placeholder="Service Name"
                                    value={serviceName}
                                    onChange={(e) => setServiceName(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="row mb-4 mt-4">
                                <select
                                    value={serviceCategory}
                                    onChange={(e) => setServiceCategory(e.target.value)}
                                    required
                                >
                                    <option value="N/A">Select an Option</option>
                                    <option value="Grooming">Grooming</option>
                                    <option value="Bath">Bath</option>
                                    <option value="Veterinary">Veterinary</option>
                                    <option value="Day Care">Day Care</option>
                                </select>
                            </div>
                            <div className="row mt-4">
                                <input
                                    type="text"
                                    placeholder="Service Price"
                                    value={servicePrice}
                                    onChange={(e) => setServicePrice(e.target.value)}
                                    required
                                />
                                            
                            </div>
                            <div className="row mt-4">
                                <button type="submit">{service ? "Update Service" : "Add Service"}</button>
                            </div>
                        </div>
                        <div className="col-3"></div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ServicesForm;
