import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { Line, Bar } from "react-chartjs-2";
import { Card, Table } from "react-bootstrap";
import { useSelector } from "react-redux";

// Helper function to generate linearly interpolated data points.
const generateLineData = (start, end, numPoints) => {
  const step = (end - start) / (numPoints - 1);
  return Array.from({ length: numPoints }, (_, i) => start + i * step);
};

const CashBalanceChart = () => {
  const API_URL = process.env.REACT_APP_BASE_URL;
  const {
    userLogin: { userInfo },
  } = useSelector((state) => state);

  // State for combined monthly cash balance + financial dashboard data.
  const [dataResponse, setDataResponse] = useState({});
  
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [year, setYear] = useState(new Date().getFullYear());
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");

  // Fetch shop users.
  const fetchUsers = useCallback(async () => {
    try {
      const { data } = await axios.get(`${API_URL}api/ShowShopView/`, {
        headers: { Authorization: `Bearer ${userInfo.token}` },
      });
      setUsers(data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  }, [API_URL, userInfo.token]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  // Fetch combined data from MonthlyCashBalanceAPIView.
  // (Assumes that this endpoint now returns additional fields:
  //  revenue, cogs, operating_expenses)
  const fetchCombinedData = useCallback(async () => {
    try {
      const { data } = await axios.get(`${API_URL}inventory/cash-balance/`, {
        params: { month, year, store: selectedUser },
        headers: { Authorization: `Bearer ${userInfo.token}` },
      });
      setDataResponse(data);
    } catch (error) {
      console.error("Error fetching combined cash balance data", error);
    }
  }, [API_URL, month, year, selectedUser, userInfo.token]);

  useEffect(() => {
    fetchCombinedData();
  }, [fetchCombinedData]);

  // -----------------------
  // Right Column: Monthly Cash Balance Chart
  // -----------------------
  const monthlyChartData = {
    labels: ["Opening Balance", "Cash Income", "Cash Expense", "Projected Balance"],
    datasets: [
      {
        label: "Cash Flow",
        data: [
          dataResponse.opening_balance || 0,
          dataResponse.total_cash_income || 0,
          dataResponse.total_cash_expense || 0,
          dataResponse.projected_balance || 0,
        ],
        backgroundColor: [
          "rgba(75, 192, 192, 0.6)",
          "rgba(54, 162, 235, 0.6)",
          "rgba(255, 99, 132, 0.6)",
          "rgba(153, 102, 255, 0.6)",
        ],
      },
    ],
  };

  const monthlyChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
        text: "Monthly Cash Flow (Waterfall Chart)",
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  // -----------------------
  // Left Column: Multi-Product Break-Even Analysis
  // -----------------------
  // Data from backend (from MonthlyCashBalanceAPIView)
  const totalSales = dataResponse.total_cash_income || 0; // total sales/revenue
  const cogs = dataResponse.cogs || 0;
  const operatingExpenses = dataResponse.operating_expenses || 0;
  const breakEvenRevenue = cogs + operatingExpenses; // total cost to cover

  // We'll generate 11 points from 0% to 100% (in steps of 10%).
  const numPoints = 11;
  const units = Array.from({ length: numPoints }, (_, i) => i * 10);

  // For each revenue stream, calculate its full contribution margin at 100% capacity.
  const groomingFull = dataResponse.grooming || 0;
  const daycareFull = dataResponse.daycare || 0;
  const productsFull = dataResponse.products || 0;

  // Interpolate cumulative contribution margins for each stream.
  const groomingData = units.map(x => (x / 100) * groomingFull);
  const daycareData = units.map(x => (x / 100) * daycareFull);
  const productsData = units.map(x => (x / 100) * productsFull);

  // Build the stacked area for revenue streams.
  const revenueStackData = {
    labels: units, // "Equivalent Units (%)"
    datasets: [
      {
        label: "Grooming",
        data: groomingData,
        fill: true,
        backgroundColor: "rgba(0, 123, 255, 0.6)",
        borderColor: "rgba(0, 123, 255, 1)",
        stack: 'revenue'
      },
      {
        label: "Daycare",
        data: daycareData,
        fill: true,
        backgroundColor: "rgba(40, 167, 69, 0.6)",
        borderColor: "rgba(40, 167, 69, 1)",
        stack: 'revenue'
      },
      {
        label: "Products",
        data: productsData,
        fill: true,
        backgroundColor: "rgba(255, 193, 7, 0.6)",
        borderColor: "rgba(255, 193, 7, 1)",
        stack: 'revenue'
      },
    ],
  };

  // Build the Total Cost line as a constant line equal to breakEvenRevenue.
  const totalCostData = units.map(() => breakEvenRevenue);

  // Now combine into one chart dataset: we want the stacked area for revenue,
  // and on top, an overlay for the total cost line.
  const breakEvenChartData = {
    labels: units,
    datasets: [
      ...revenueStackData.datasets, // revenue streams (stacked area)
      {
        label: "Total Cost",
        data: totalCostData,
        fill: false,
        borderColor: "rgba(255, 0, 0, 0.8)",
        borderDash: [5, 5],
        borderWidth: 2,
        pointRadius: 0,
        order: 2,
        stack: 'cost'
      },
    ],
  };

  // Set the maximum of the y-axis to be the larger of totalSales or breakEvenRevenue.
  const yMax = Math.max(totalSales, breakEvenRevenue) * 1.05; // add a 5% headroom

  const breakEvenChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
        text: "Multi-Product Break-Even Analysis (Weighted Contribution Margin)",
      },
    },
    scales: {
      x: {
        stacked: true,
        title: {
          display: true,
          text: "Equivalent Units (%)",
        },
      },
      y: {
        stacked: true,
        beginAtZero: true,
        title: {
          display: true,
          text: "Cumulative Contribution ($)",
        },
        max: yMax,
      },
    },
  };

  return (
    <Card>
      <Card.Body>
        <h4>Financial Overview</h4>
        {/* Shared Filter Inputs */}
        <div className="d-flex mb-4">
          <div>
            <label>Month</label>
            <input
              type="number"
              value={month}
              onChange={(e) => setMonth(Number(e.target.value))}
              min="1"
              max="12"
            />
          </div>
          <div className="ms-4">
            <label>Year</label>
            <input
              type="number"
              value={year}
              onChange={(e) => setYear(Number(e.target.value))}
              min="2000"
              max={new Date().getFullYear()}
            />
          </div>
          <div className="ms-4">
            <label>Shop</label>
            <select
              value={selectedUser}
              onChange={(e) => setSelectedUser(e.target.value)}
            >
              <option value="">All Shops</option>
              {users.map((user) => (
                <option key={user.id} value={user.id}>
                  {user.ShopName}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* Two-column layout: Left for Break-even Analysis, Right for Monthly Cash Balance */}
        <div className="row">
          {/* Left Column: Multi-Product Break-Even Analysis */}
          <div className="col-md-6">
            <Card className="mb-4">
              <Card.Body>
                <h5>Break-even Analysis</h5>
                <div style={{ height: "400px" }}>
                  <Line data={breakEvenChartData} options={breakEvenChartOptions} />
                </div>
              </Card.Body>
            </Card>
          </div>

          {/* Right Column: Monthly Cash Balance */}
          <div className="col-md-6">
            <Card className="mb-4">
              <Card.Body>
                <h5>Monthly Cash Balance</h5>
                <div style={{ height: "400px" }}>
                  <Bar data={monthlyChartData} options={monthlyChartOptions} />
                </div>
                <Table striped bordered hover className="mt-4">
                  <thead>
                    <tr>
                      <th>Cash Flow Details</th>
                      <th>Amount ($)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Total Cash Income</td>
                      <td>${dataResponse.total_cash_income?.toFixed(2)}</td>
                    </tr>
                    <tr>
                      <td>Total Cash Expense</td>
                      <td>${dataResponse.total_cash_expense?.toFixed(2)}</td>
                    </tr>
                    <tr>
                      <td>Accounts Payable</td>
                      <td>${dataResponse.accounts_payable?.toFixed(2)}</td>
                    </tr>
                    <tr>
                      <td>Accounts Receivable</td>
                      <td>${dataResponse.accounts_receivable?.toFixed(2)}</td>
                    </tr>
                    <tr>
                      <td>Working Capital</td>
                      <td>${dataResponse.working_capital?.toFixed(2)}</td>
                    </tr>
                    <tr>
                      <td>Projected Balance</td>
                      <td>${dataResponse.projected_balance?.toFixed(2)}</td>
                    </tr>
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default React.memo(CashBalanceChart);
