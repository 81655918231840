import React, { useState, useEffect, useCallback } from "react";
import { Bar } from "react-chartjs-2";
import { Card, Button } from "react-bootstrap";
import axios from "axios";
import "chart.js/auto";
import { useSelector } from "react-redux";

const RevenueCogs = () => {
  const API_URL = process.env.REACT_APP_BASE_URL;
  const {
    userLogin: { userInfo },
  } = useSelector((state) => state);

  const [chartData, setChartData] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  const [users, setUsers] = useState([]);

  const fetchUsers = useCallback(async () => {
    try {
      const { data } = await axios.get(`${API_URL}api/ShowShopView/`, {
        headers: { Authorization: `Bearer ${userInfo.token}` },
      });
      setUsers(data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  }, [API_URL, userInfo.token]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.get(`${API_URL}inventory/financial-cards/`, {
        params: {
          start_date: startDate,
          end_date: endDate,
          shop: selectedUser,
        },
      });
      const saleData = data.sale_data;
      const updatedChartData = {
        labels: ["Revenue & COGS"],
        datasets: [
          {
            label: "Revenue",
            data: [saleData.revenue],
            backgroundColor: "rgba(54, 162, 235, 0.6)",
          },
          {
            label: "COGS",
            data: [saleData.cogs],
            backgroundColor: "rgba(75, 192, 192, 0.6)",
          },
        ],
      };
      setChartData(updatedChartData);
    } catch (error) {
      console.error("Error fetching financial data:", error);
    }
  };

  return (
    <div>
      <Card>
        <Card.Body>
          <h4>Revenue & COGS</h4>
          <form
            onSubmit={handleSubmit}
            className="d-flex flex-row justify-content-evenly mb-4 bg-white border-0"
          >
            <div className="d-flex flex-column me-4">
              <label>Start Date</label>
              <input
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </div>
            <div className="d-flex flex-column me-4">
              <label>End Date</label>
              <input
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </div>
            <div className="d-flex flex-column me-4">
              <label>Shop</label>
              <select
                value={selectedUser}
                onChange={(e) => setSelectedUser(e.target.value)}
              >
                <option value="">Select Shop</option>
                {users.map((user) => (
                  <option key={user.id} value={user.id}>
                    {user.ShopName}
                  </option>
                ))}
              </select>
            </div>
            <Button type="submit" variant="primary" className="align-self-end">
              Submit
            </Button>
          </form>
          {chartData ? (
            <Bar data={chartData} height={200} width={400} />
          ) : (
            <p>No available data</p>
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

export default React.memo(RevenueCogs);
