import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { Card, Form, Button } from "react-bootstrap";
import GaugeChart from "react-gauge-chart";
import { useSelector } from "react-redux";

const Cards = () => {
  const API_URL = process.env.REACT_APP_BASE_URL;
  const {
    userLogin: { userInfo },
  } = useSelector((state) => state);

  // Calculate the first and last dates of the current month.
  const currentDate = new Date();
  const firstDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  )
    .toISOString()
    .split("T")[0];
  const lastDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0
  )
    .toISOString()
    .split("T")[0];

  const [filters, setFilters] = useState({
    startDate: firstDayOfMonth,
    endDate: lastDayOfMonth,
    shop: "",
  });
  const [financialData, setFinancialData] = useState({
    gross_profit_margin: null,
    net_profit_margin: null,
    opex_ratio: null,
    operating_profit_margin: null,
    // Removed break_even_data from here.
  });
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");

  const fetchUsers = useCallback(async () => {
    try {
      const { data } = await axios.get(`${API_URL}api/ShowShopView/`, {
        headers: { Authorization: `Bearer ${userInfo.token}` },
      });
      setUsers(data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  }, [API_URL, userInfo.token]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const fetchFinancialCardsData = useCallback(() => {
    setLoading(true);
    axios
      .get(`${API_URL}inventory/financial-cards/`, {
        params: {
          start_date: filters.startDate,
          end_date: filters.endDate,
          shop: selectedUser,
        },
      })
      .then((response) => {
        setFinancialData(response.data.sale_data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching financial data:", error);
        setLoading(false);
      });
  }, [API_URL, filters, selectedUser]);

  useEffect(() => {
    fetchFinancialCardsData();
  }, [fetchFinancialCardsData]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prev) => ({ ...prev, [name]: value }));
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      {/* Filters */}
      <div className="d-flex mb-4">
        <Form.Group className="me-3">
          <Form.Label>Start Date</Form.Label>
          <Form.Control
            type="date"
            name="startDate"
            value={filters.startDate}
            onChange={handleFilterChange}
          />
        </Form.Group>
        <Form.Group className="me-3">
          <Form.Label>End Date</Form.Label>
          <Form.Control
            type="date"
            name="endDate"
            value={filters.endDate}
            onChange={handleFilterChange}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Shop</Form.Label>
          <Form.Select
            name="shop"
            value={selectedUser}
            onChange={(e) => setSelectedUser(e.target.value || "")}
          >
            <option value="">All Shops</option>
            {users.map((user) => (
              <option key={user.id} value={user.id}>
                {user.ShopName}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
        <Button
          variant="primary"
          onClick={fetchFinancialCardsData}
          className="ms-3 align-self-end"
        >
          Apply Filters
        </Button>
      </div>

      {/* KPI Cards */}
      <div className="d-flex flex-wrap mb-4">
        {[
          { label: "Gross Profit Margin", value: financialData.gross_profit_margin },
          { label: "Opex Ratio", value: financialData.opex_ratio },
          { label: "Net Profit Margin", value: financialData.net_profit_margin },
          { label: "Operating Profit Margin", value: financialData.operating_profit_margin },
        ].map((metric, index) => (
          <Card key={index} style={{ width: "18rem", margin: "1rem" }}>
            <Card.Body>
              <GaugeChart
                id={`metric-${index}`}
                nrOfLevels={20}
                percent={metric.value != null ? metric.value / 100 : 0}
                colors={["#FF0000", "#FFFF00", "#00FF00"]}
                textColor="#000000"
              />
              <Card.Title>{metric.label}</Card.Title>
            </Card.Body>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default Cards;
