import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Card, Form, Button } from 'react-bootstrap';

const IncomeStatement = () => {
  const apiUrl = process.env.REACT_APP_BASE_URL;

  const [data, setData] = useState({});
  const [year, setYear] = useState(new Date().getFullYear());
  const [loading, setLoading] = useState(true);

  const [showRevenueDetails, setShowRevenueDetails] = useState(false);
  const [showCogsDetails, setShowCogsDetails] = useState(false);
  const [showOpsDetails, setShowOpsDetails] = useState(false);

  useEffect(() => {
    setLoading(true);
    axios.get(`${apiUrl}inventory/income-statement/?year=${year}`)
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  }, [year, apiUrl]);

  if (loading) return <p>Loading income statement...</p>;

  // Determine union of all sales categories across months.
  const salesCategories = Array.from(new Set(
    Object.values(data).flatMap(monthData => Object.keys(monthData.sales || {}))
  ));

  // Determine union of all expense categories across months.
  const expenseCategories = Array.from(new Set(
    Object.values(data).flatMap(monthData => Object.keys(monthData.expenses || {}))
  ));

  const months = [
    '', 'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  const reloadData = () => {
    setLoading(true);
    axios.get(`${apiUrl}inventory/income-statement/?year=${year}`)
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  const groupNames = Array.from(new Set(
    Object.values(data).flatMap(monthData => Object.keys(monthData.grouped_sales || {}))
  ));

  const expenseGroupNamesCOGS = Array.from(new Set(
    Object.values(data).flatMap(monthData => Object.keys(monthData.grouped_expenses_cogs || {}))
  ));

  const expenseGroupNamesOps = Array.from(new Set(
    Object.values(data).flatMap(monthData => Object.keys(monthData.grouped_expenses_ops || {}))
  ));

  const formatMXN = (value) =>
    new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(value);

  // Helper: sum up total revenue for a month
  const getTotalRevenue = (monthKey) => {
    let total = 0;
    if (data[monthKey]?.grouped_sales) {
      Object.values(data[monthKey].grouped_sales).forEach((grp) => {
        total += parseFloat(grp.total_sales) || 0;
      });
    }
    return total;
  };

  // Helper: sum up total COGS for a month
  const getTotalCOGS = (monthKey) => {
    let total = 0;
    if (data[monthKey]?.grouped_expenses_cogs) {
      Object.values(data[monthKey].grouped_expenses_cogs).forEach((grp) => {
        total += parseFloat(grp.total_expense) || 0;
      });
    }
    return total;
  };

  // Helper: sum up total Operating costs for a month
  const getTotalOps = (monthKey) => {
    let total = 0;
    if (data[monthKey]?.grouped_expenses_ops) {
      Object.values(data[monthKey].grouped_expenses_ops).forEach((grp) => {
        total += parseFloat(grp.total_expense) || 0;
      });
    }
    return total;
  };

  // Calculate monthly gross profit
  const getGrossProfit = (monthKey) => getTotalRevenue(monthKey) - getTotalCOGS(monthKey);

  // Calculate monthly EBIT = GrossProfit - Ops
  const getEBIT = (monthKey) => getGrossProfit(monthKey) - getTotalOps(monthKey);


  return (
    <Card>
      <Card.Body>
        <h4>Income Statement for {year}</h4>
        <Form className="mb-4">
          <Form.Group controlId="yearSelect">
            <Form.Label>Select Year</Form.Label>
            <Form.Control 
              type="number"
              value={year}
              onChange={(e) => setYear(e.target.value)}
            />
          </Form.Group>
          <Button className="mt-2" onClick={reloadData}>
            Reload
          </Button>
        </Form>
        
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Division / Category</th>
              {Array.from({ length: 12 }, (_, i) => (
                <th key={i+1}>{months[i+1]}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <Button
                  variant="link"
                  onClick={() => setShowRevenueDetails(!showRevenueDetails)}
                >
                  {showRevenueDetails ? '−' : '+'}
                </Button>{' '}
                <strong>Total Revenue</strong>
              </td>
              {months.slice(1).map((mName, idx) => {
                const monthKey = String(idx + 1);
                const monthTotal = getTotalRevenue(monthKey);
                return (
                  <td key={mName}>
                    <strong>
                      {monthTotal ? formatMXN(monthTotal) : '-'}
                    </strong>
                  </td>
                );
              })}
            </tr>
            {showRevenueDetails &&
              groupNames.map((group) => (
                <React.Fragment key={group}>
                  <tr>
                    <td><strong>{group}</strong></td>
                    {months.slice(1).map((mName, idx) => {
                      const monthKey = String(idx + 1);
                      const groupData =
                        data[monthKey]?.grouped_sales?.[group];
                      const val = groupData
                        ? parseFloat(groupData.total_sales)
                        : 0;
                      return (
                        <td key={mName}>
                          {val ? formatMXN(val) : '-'}
                        </td>
                      );
                    })}
                  </tr>
                  {Object.keys(
                    data['1']?.grouped_sales?.[group]?.subcategories || {}
                  ).map((subcat) => (
                    <tr key={`${group}-${subcat}`}>
                      <td style={{ paddingLeft: '40px' }}>{subcat}</td>
                      {months.slice(1).map((mName, idx) => {
                        const monthKey = String(idx + 1);
                        const subcatVal =
                          data[monthKey]?.grouped_sales?.[group]
                            ?.subcategories?.[subcat] || 0;
                        return (
                          <td key={mName}>
                            {subcatVal ? formatMXN(subcatVal) : '-'}
                          </td>
                        );
                      })}
                    </tr>
                  ))}
                </React.Fragment>
              ))}
            <tr>
              <td>
                <Button
                  variant="link"
                  onClick={() => setShowCogsDetails(!showCogsDetails)}
                >
                  {showCogsDetails ? '−' : '+'}
                </Button>{' '}
                <strong>Total Cost of Good Sold</strong>
              </td>
              {months.slice(1).map((mName, idx) => {
                const monthKey = String(idx + 1);
                const monthTotal = getTotalCOGS(monthKey);
                return (
                  <td key={mName}>
                    <strong>
                      {monthTotal ? formatMXN(monthTotal) : '-'}
                    </strong>
                  </td>
                );
              })}
            </tr>
            {showCogsDetails &&
              expenseGroupNamesCOGS.map((group) => (
                <React.Fragment key={`expense-${group}`}>
                  <tr>
                    <td>
                      <strong>
                        {group}
                      </strong>
                    </td>
                    {months.slice(1).map((mName, idx) => {
                      const monthKey = String(idx + 1);
                      const groupData =
                        data[monthKey]?.grouped_expenses_cogs?.[group];
                      const val = groupData
                        ? parseFloat(groupData.total_expense)
                        : 0;
                      return (
                        <td key={mName}>
                          {val ? formatMXN(val) : '-'}
                        </td>
                      );
                    })}
                  </tr>
                  {Object.keys(
                    data['1']?.grouped_expenses_cogs?.[group]?.subcategories ||
                      {}
                  ).map((subcat) => (
                    <tr key={`cogs-${group}-${subcat}`}>
                      <td style={{ paddingLeft: '40px' }}>{subcat}</td>
                      {months.slice(1).map((mName, idx) => {
                        const monthKey = String(idx + 1);
                        const subcatVal =
                          data[monthKey]?.grouped_expenses_cogs?.[group]
                            ?.subcategories?.[subcat] || 0;
                        return (
                          <td key={mName}>
                            {subcatVal ? formatMXN(subcatVal) : '-'}
                          </td>
                        );
                      })}
                    </tr>
                  ))}
                </React.Fragment>
              ))}
            <tr>
              <td><strong>Gross Profit</strong></td>
              {months.slice(1).map((mName, idx) => {
                const monthKey = String(idx + 1);
                const gp = getGrossProfit(monthKey);
                return (
                  <td key={mName}>
                    <strong>{formatMXN(gp)}</strong>
                  </td>
                );
              })}
            </tr>
            <tr>
              <td>
                <Button
                  variant="link"
                  onClick={() => setShowOpsDetails(!showOpsDetails)}
                >
                  {showOpsDetails ? '−' : '+'}
                </Button>{' '}
                <strong>Total Operation Costs</strong>
              </td>
              {months.slice(1).map((mName, idx) => {
                const monthKey = String(idx + 1);
                const ops = getTotalOps(monthKey);
                return (
                  <td key={mName}>
                    <strong>{ops ? formatMXN(ops) : '-'}</strong>
                  </td>
                );
              })}
            </tr>
            {showOpsDetails &&
              expenseGroupNamesOps.map((group) => (
                <React.Fragment key={`ops-${group}`}>
                  <tr>
                    <td style={{ paddingLeft: '20px' }}>
                      <strong>{group}</strong>
                    </td>
                    {months.slice(1).map((mName, idx) => {
                      const monthKey = String(idx + 1);
                      const groupData =
                        data[monthKey]?.grouped_expenses_ops?.[group];
                      const val = groupData
                        ? parseFloat(groupData.total_expense)
                        : 0;
                      return (
                        <td key={mName}>
                          {val ? formatMXN(val) : '-'}
                        </td>
                      );
                    })}
                  </tr>
                  {/* Subcategories */}
                  {Object.keys(
                    data['1']?.grouped_expenses_ops?.[group]?.subcategories ||
                      {}
                  ).map((subcat) => (
                    <tr key={`ops-${group}-${subcat}`}>
                      <td style={{ paddingLeft: '40px' }}>{subcat}</td>
                      {months.slice(1).map((mName, idx) => {
                        const monthKey = String(idx + 1);
                        const subcatVal =
                          data[monthKey]?.grouped_expenses_ops?.[group]
                            ?.subcategories?.[subcat] || 0;
                        return (
                          <td key={mName}>
                            {subcatVal ? formatMXN(subcatVal) : '-'}
                          </td>
                        );
                      })}
                    </tr>
                  ))}
                </React.Fragment>
              ))}
            <tr>
              <td>
                <strong>EBIT</strong>
              </td>
              {months.slice(1).map((mName, idx) => {
                const monthKey = String(idx + 1);
                const ebit = getEBIT(monthKey); // (GrossProfit - Ops)
                return (
                  <td key={mName}>
                    <strong>{formatMXN(ebit)}</strong>
                  </td>
                );
              })}
            </tr>
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export default IncomeStatement;

