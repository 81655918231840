import React, { useState, useEffect, useCallback, useMemo } from "react";
import axios from "axios";
import moment from "moment-timezone";
import "./pos.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlusCircle,
  faMinusCircle,
  faTrashCan,
  faCircleDot,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { useSelector, useDispatch } from "react-redux";
import { removeFromCart } from "../../actions/cartActions";

/**
 * Custom hook to debounce any fast changing value.
 * @param {any} value The value to debounce.
 * @param {number} delay Delay in milliseconds.
 * @returns The debounced value.
 */
function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => setDebouncedValue(value), delay);
    return () => clearTimeout(handler);
  }, [value, delay]);
  return debouncedValue;
}

/**
 * Renders an individual product card.
 */
const ProductCard = React.memo(({ product, onSelect, apiUrl }) => (
  <div className="product-card" onClick={() => onSelect(product)}>
    <img
      src={`${apiUrl}${product.image}`}
      alt={product.isService ? product.name : product.productName}
      className="product-image"
      style={{ height: "100px" }}
    />
    <div className="product-info">
      <div className="product-name">
        {product.isService ? product.name : product.productName}
      </div>
      <div className="product-price">${product.price}</div>
    </div>
  </div>
));

/**
 * Renders an individual client search result.
 */
const ClientResultItem = React.memo(({ client, onSelect }) => (
  <li onClick={() => onSelect(client)} className="client-selection-pos">
    {client.FirstName} {client.LastName}
  </li>
));

/**
 * Renders a single cart item with quantity, discount, and remove options.
 */
const CartItem = React.memo(
  ({ item, onUpdateQuantity, onUpdateDiscount, onPriceChange, onRemove }) => (
    <div className="cart-item" key={item.uniqueId}>
      <div>
        <p>{item.isService ? item.name : item.productName}</p>
        <p>
          $<input
            type="number"
            value={item.price}
            onChange={(e) => onPriceChange(item.uniqueId, e.target.value)}
            style={{ width: "80px" }}
          />
        </p>
      </div>
      <div>
        <button
          onClick={() =>
            onUpdateQuantity(item.id, item.uniqueId, item.quantity + 1)
          }
        >
          <FontAwesomeIcon icon={faPlusCircle} />
        </button>
        <span>{item.quantity}</span>
        <button
          onClick={() =>
            onUpdateQuantity(item.id, item.uniqueId, item.quantity - 1)
          }
        >
          <FontAwesomeIcon icon={faMinusCircle} />
        </button>
      </div>
      <div>
        <button
          onClick={() => onUpdateDiscount(item.uniqueId, item.discount + 1)}
        >
          <FontAwesomeIcon icon={faPlusCircle} />
        </button>
        <span>{item.discount.toFixed(2)}%</span>
        <button
          onClick={() => onUpdateDiscount(item.uniqueId, item.discount - 1)}
        >
          <FontAwesomeIcon icon={faMinusCircle} />
        </button>
      </div>
      <p>
        Total: $
        {(item.price * item.quantity * (1 - item.discount / 100)).toFixed(2)}
      </p>
      <button onClick={() => onRemove(item.uniqueId)}>
        <FontAwesomeIcon icon={faTrashCan} />
      </button>
    </div>
  )
);

/**
 * Renders the input controls for a given payment method.
 */
const PaymentMethodInput = React.memo(
  ({ method, amount, onAmountChange, onSetRemaining, onSetFull }) => (
    <div className="payment-method-detail">
      <h4>{method.charAt(0).toUpperCase() + method.slice(1)} Payment</h4>
      <div>
        <input
          type="number"
          value={amount}
          onChange={(e) => onAmountChange(e, method)}
          placeholder={`Enter ${method} amount`}
        />
        <button
          onClick={() => onSetRemaining(method)}
          data-toggle="tooltip"
          data-placement="top"
          title="Remaining Amount"
        >
          <FontAwesomeIcon icon={faCircleDot} />
        </button>
        <button
          onClick={() => onSetFull(method)}
          data-toggle="tooltip"
          data-placement="top"
          title="Full Amount"
        >
          <FontAwesomeIcon icon={faCheck} />
        </button>
      </div>
    </div>
  )
);

const PosSystem = () => {
  const dispatch = useDispatch();

  // Retrieve shop and user info from Redux state
  const shop = useSelector((state) => state.shop);
  const { shopId } = shop;
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const apiUrl = process.env.REACT_APP_BASE_URL;

  // Local state definitions
  const [shopTimezone, setShopTimezone] = useState("UTC");
  const [selectedItems, setSelectedItems] = useState([]);
  const reduxCartItems = useSelector((state) => state.cart.selectedItems);
  const [searchTerm, setSearchTerm] = useState("");
  const [products, setProducts] = useState([]);
  const [transactionStatus, setTransactionStatus] = useState(null);
  const [paymentAmount, setPaymentAmount] = useState({
    cash: 0,
    card: 0,
    transfer: 0,
  });
  const [gratuity, setGratuity] = useState(0);
  const [showPaymentInputs, setShowPaymentInputs] = useState({
    cash: false,
    card: false,
    transfer: false,
  });
  const [clientSearchTerm, setClientSearchTerm] = useState("");
  const [clientResults, setClientResults] = useState([]);
  const [selectedClientId, setSelectedClientId] = useState(null);
  const [previouslySelectedClient, setPreviouslySelectedClient] = useState(null);
  const [isProcessingTransaction, setIsProcessingTransaction] = useState(false);
  const [promotionResponse, setPromotionResponse] = useState(null);
  const [promotionDiscount, setPromotionDiscount] = useState(0);
  const [bookedPackages, setBookedPackages] = useState([]);
  const [isPromotionApplied, setIsPromotionApplied] = useState(false);
  const [appliedPromotionId, setAppliedPromotionId] = useState(null);
  const [recommendations, setRecommendations] = useState([]);


  // Debounce client search term to avoid too many API calls
  const debouncedClientSearchTerm = useDebounce(clientSearchTerm, 300);

  // ------------------------- API Calls & Effects -------------------------

  // Fetch shop details (e.g. timezone)
  useEffect(() => {
    const fetchShopDetails = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}api/shopdetails/${shopId}/`,
          { headers: { Authorization: `Bearer ${userInfo.token}` } }
        );
        if (response.data?.Timezone) {
          setShopTimezone(response.data.Timezone);
        }
      } catch (error) {
        console.error("Error fetching shop details", error);
      }
    };
    if (shopId) fetchShopDetails();
  }, [shopId, apiUrl, userInfo.token]);

  useEffect(() => {
    if (selectedItems.length > 0) {
      // For simplicity, we use the last added product as the base for recommendations.
      const lastItem = selectedItems[selectedItems.length - 1];
      axios
        .get(`${apiUrl}inventory/recommendations/${lastItem.id}/`)
        .then((response) => {
          setRecommendations(response.data.recommendations);
        })
        .catch((error) => {
          console.error("Error fetching cross-sell recommendations", error);
        });
    } else {
      setRecommendations([]); // Clear recommendations if no items are selected.
    }
  }, [selectedItems, apiUrl]);

  // Merge redux cart items with local state
  const mergeCartItems = useCallback((newItemsFromRedux) => {
    setSelectedItems((prevItems) => {
      const mergedItems = [...prevItems];
      let hasChanged = false;
      newItemsFromRedux.forEach((itemFromRedux) => {
        const idx = mergedItems.findIndex(
          (item) => item.uniqueId === itemFromRedux.uniqueId
        );
        if (idx > -1) {
          const existing = mergedItems[idx];
          if (existing.quantity !== itemFromRedux.quantity) {
            hasChanged = true;
            mergedItems[idx] = { ...existing, quantity: itemFromRedux.quantity };
          }
        } else {
          hasChanged = true;
          mergedItems.push(itemFromRedux);
        }
      });
      return hasChanged ? mergedItems : prevItems;
    });
  }, []);

  useEffect(() => {
    mergeCartItems(reduxCartItems);
  }, [reduxCartItems, mergeCartItems]);

  // Placeholder effect to apply additional discounts if needed
  useEffect(() => {
    const applyDiscounts = async () => {
      if (selectedItems.length > 0) {
        // Add discount logic here
      }
    };
    applyDiscounts();
  }, [selectedItems]);

  // Perform client search using debounced search term
  const handleClientSearch = useCallback(async () => {
    if (debouncedClientSearchTerm.length < 3) {
      setClientResults([]);
      return;
    }
    try {
      const response = await axios.get(
        `${apiUrl}inventory/clients/search/?query=${debouncedClientSearchTerm}`
      );
      setClientResults(response.data);
    } catch (error) {
      console.error(
        "Error searching for clients",
        error.response?.data || error.message
      );
    }
  }, [apiUrl, debouncedClientSearchTerm]);

  useEffect(() => {
    if (debouncedClientSearchTerm.length >= 3) {
      handleClientSearch();
    } else {
      setClientResults([]);
    }
  }, [debouncedClientSearchTerm, handleClientSearch]);

  // Fetch client details and auto-select if found in redux cart items
  const fetchClientDetails = useCallback(async (clientId) => {
    try {
      const response = await axios.get(
        `${apiUrl}api/client-detail/${clientId}`
      );
      if (response.data) {
        handleClientSelect(response.data);
      }
    } catch (error) {
      console.error("Error fetching client details", error);
    }
  }, [apiUrl]);

  useEffect(() => {
    const clientItem = reduxCartItems.find((item) => item.clientId);
    if (clientItem && clientItem.clientId && selectedClientId !== clientItem.clientId) {
      fetchClientDetails(clientItem.clientId);
    }
  }, [reduxCartItems, selectedClientId, fetchClientDetails]);

  // ------------------------- Client & Promotion Logic -------------------------

  // Handle selection of a client from search results
  const handleClientSelect = useCallback((client) => {
    setSelectedClientId(client.id);
    setPreviouslySelectedClient(client);
    setClientSearchTerm("");
    setClientResults([]);
  }, []);

  // Fetch promotion details (renamed for clarity)
  const fetchClientPromotionDetails = useCallback(async (clientId) => {
    try {
      const response = await axios.get(
        `${apiUrl}payroll/check-client-promotion/${clientId}/`
      );
      setPromotionResponse(response.data);
      if (response.data && response.data.length > 0) {
        const discountPercentage = response.data[0].discount_percentage;
        setPromotionDiscount(discountPercentage);
        setIsPromotionApplied(true);
        setAppliedPromotionId(response.data[0].promotion.id);
      } else {
        setPromotionDiscount(0);
        setIsPromotionApplied(false);
        setAppliedPromotionId(null);
      }
    } catch (error) {
      console.error("Error fetching client promotion:", error);
      setPromotionResponse(null);
      setPromotionDiscount(0);
      setIsPromotionApplied(false);
      setAppliedPromotionId(null);
    }
  }, [apiUrl]);

  // Fetch any booked packages for the client
  const fetchBookedPackages = useCallback(async (clientId) => {
    if (!clientId) {
      console.error("Client ID is missing, cannot fetch booked packages.");
      return;
    }
    try {
      const response = await axios.get(
        `${apiUrl}inventory/check-booked-package/${clientId}/`
      );
      setBookedPackages(response.data);
    } catch (error) {
      console.error("Error fetching booked packages:", error);
      setBookedPackages([]);
    }
  }, [apiUrl]);

  // Apply promotions and booked package discounts whenever the client changes
  useEffect(() => {
    if (selectedClientId) {
      fetchClientPromotionDetails(selectedClientId);
      fetchBookedPackages(selectedClientId);
    }
  }, [selectedClientId, fetchClientPromotionDetails, fetchBookedPackages]);

  // Apply the promotion discount to one service item if applicable
  useEffect(() => {
    if (promotionDiscount > 0) {
      applyPromotionDiscount(promotionDiscount);
    }
  }, [promotionDiscount]);

  // Deduct 100% discount for service items if a matching booked package is found
  const applyBookedPackageDiscount = useCallback(() => {
    setSelectedItems((prevSelectedItems) =>
      prevSelectedItems.map((item) => {
        if (item.isService) {
          const packageFound = bookedPackages.find(
            (pkg) => pkg.service_id === item.id && pkg.remaining_services > 0
          );
          if (packageFound) {
            packageFound.remaining_services -= 1;
            return {
              ...item,
              discount: 100,
              isPrepaid: true,
              isApplied: true,
              pkgId: packageFound.id,
            };
          }
        }
        return item;
      })
    );
  }, [bookedPackages]);

  useEffect(() => {
    if (bookedPackages.length > 0) {
      applyBookedPackageDiscount();
    }
  }, [bookedPackages, applyBookedPackageDiscount]);

  // Apply promotion discount to one applicable service item
  const applyPromotionDiscount = (discountPercentage) => {
    let discountApplied = false;
    setSelectedItems((prevSelectedItems) =>
      prevSelectedItems.map((item) => {
        if (item.isService && !discountApplied && !item.isApplied) {
          discountApplied = true;
          return { ...item, discount: discountPercentage };
        }
        return item;
      })
    );
  };

  // Display the currently selected client or fallback if not available
  const displaySelectedClient = useCallback(() => {
    const selectedClient =
      clientResults.find((client) => client.id === selectedClientId) ||
      previouslySelectedClient;
    return selectedClient
      ? `${selectedClient.FirstName} ${selectedClient.LastName}`
      : "No client selected";
  }, [clientResults, selectedClientId, previouslySelectedClient]);

  // ------------------------- Derived Calculations (Memoized) -------------------------

  const subtotal = useMemo(
    () =>
      selectedItems.reduce((acc, item) => acc + item.price * item.quantity, 0),
    [selectedItems]
  );

  const grandTotal = useMemo(() => {
    const total = selectedItems.reduce((acc, item) => {
      return acc + (item.discount
        ? (item.price - (item.price * item.discount) / 100) * item.quantity
        : item.price * item.quantity);
    }, 0);
    return total + gratuity;
  }, [selectedItems, gratuity]);

  const totalPayments = useMemo(
    () =>
      Object.values(paymentAmount).reduce((acc, amount) => {
        const numericAmount =
          typeof amount === "number" ? amount : parseFloat(amount);
        return acc + numericAmount;
      }, 0),
    [paymentAmount]
  );

  const calculatedDiscountPercentage = useMemo(() => {
    const discountedTotal = selectedItems.reduce((acc, item) => {
      return acc + item.price * item.quantity * (1 - (item.discount || 0) / 100);
    }, 0);
    const discountAmount = subtotal - discountedTotal;
    return subtotal > 0 ? (discountAmount / subtotal) * 100 : 0;
  }, [selectedItems, subtotal]);

  const absoluteDiscount = useMemo(() => {
    return selectedItems.reduce(
      (acc, item) => acc + (item.price * item.quantity * (item.discount || 0)) / 100,
      0
    );
  }, [selectedItems]);

  // ------------------------- Payment & Product Handlers -------------------------

  const handlePaymentAmountChange = (e, method) => {
    const value = e.target.value;
    const numericValue = value === "" ? 0 : parseFloat(value);
    setPaymentAmount((prev) => ({ ...prev, [method]: numericValue }));
  };

  const handleSetRemainingAmount = (method) => {
    setPaymentAmount((prev) => ({
      ...prev,
      [method]: grandTotal - totalPayments,
    }));
  };

  const handleSetFullAmount = (method) => {
    setPaymentAmount((prev) => ({ ...prev, [method]: grandTotal }));
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // Search for products based on search term
  const handleSearch = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}inventory/search/combined/pos/?query=${searchTerm}`
      );
      const { inventory, services } = response.data;
      const combinedResults = [
        ...inventory,
        ...services.map((service) => ({ ...service, isService: true })),
      ];
      setProducts(combinedResults);
    } catch (error) {
      console.error(
        "Error searching for products",
        error.response?.data || error.message
      );
    }
  };

  // Handle product selection and add to cart
  const handleProductSelect = (product) => {
    const uniqueId = product.isService
      ? `service-${product.id}`
      : `product-${product.id}`;
    const cost = product.cost_per_piece || product.cost_per_hundred_grams || 0;
    const price = product.price;
    const margin = ((price - cost) / price) * 100;

    // Check if the item is already in the cart
    const existingItemIndex = selectedItems.findIndex(
      (item) => item.uniqueId === uniqueId
    );
    if (existingItemIndex !== -1) {
      const existingItem = selectedItems[existingItemIndex];
      if (!product.isService && existingItem.quantity >= product.quantityStock) {
        alert(
          `Cannot increase ${product.productName || "item"} quantity. Stock limit reached.`
        );
      } else {
        setSelectedItems((prev) => {
          const updatedItems = [...prev];
          updatedItems[existingItemIndex] = {
            ...existingItem,
            quantity: existingItem.quantity + 1,
          };
          return updatedItems;
        });
      }
    } else if (product.isService || product.quantityStock > 0) {
      let discount = 0;
      let pkgId = null;
      if (product.isService) {
        const packageFound = bookedPackages.find(
          (pkg) => pkg.service_id === product.id && pkg.remaining_services > 0
        );
        if (packageFound) {
          discount = 100;
          pkgId = packageFound.id;
          packageFound.remaining_services -= 1;
        }
      }
      setSelectedItems((prev) => [
        ...prev,
        { ...product, quantity: 1, discount, margin, uniqueId },
      ]);
    } else {
      alert("Cannot add item to cart. No stock available.");
    }
    // Re-apply client promotions if a client is already selected
    if (selectedClientId) {
      fetchClientPromotionDetails(selectedClientId);
      fetchBookedPackages(selectedClientId);
    }
  };

  // Update quantity for a cart item, checking stock if needed
  const updateQuantity = async (id, uniqueId, newQuantity) => {
    const item = selectedItems.find((item) => item.uniqueId === uniqueId);
    if (!item) {
      console.error("Item with uniqueId", uniqueId, "not found");
      return;
    }
    if (uniqueId.includes("service")) {
      setSelectedItems((prev) =>
        prev.map((item) =>
          item.uniqueId === uniqueId
            ? { ...item, quantity: Math.max(0, newQuantity) }
            : item
        )
      );
    } else if (uniqueId.includes("product")) {
      const currentStock = await fetchProductStock(item.id);
      if (newQuantity > currentStock) {
        alert("The requested quantity exceeds the available stock.");
      } else {
        setSelectedItems((prev) =>
          prev.map((item) =>
            item.uniqueId === uniqueId
              ? { ...item, quantity: Math.max(0, newQuantity) }
              : item
          )
        );
      }
    }
  };

  const fetchProductStock = async (productId) => {
    try {
      const response = await axios.get(
        `${apiUrl}inventory/inventory/${productId}/`
      );
      return response.data.quantityStock;
    } catch (error) {
      console.error("Error fetching product stock", error);
      return 0;
    }
  };

  // Update discount; separates logic for services and other items
  const updateDiscount = (uniqueId, newDiscount) => {
    if (uniqueId.includes("service")) {
      updateServiceDiscount(uniqueId, newDiscount);
    } else {
      updateOtherDiscount(uniqueId, newDiscount);
    }
  };

  const updateServiceDiscount = (uniqueId, newDiscount) => {
    setSelectedItems((prev) =>
      prev.map((item) =>
        item.uniqueId === uniqueId
          ? { ...item, discount: Math.max(0, Math.min(100, newDiscount)) }
          : item
      )
    );
  };

  const updateOtherDiscount = (uniqueId, newDiscount) => {
    setSelectedItems((prev) =>
      prev.map((item) => {
        if (item.uniqueId === uniqueId) {
          const maxDiscountAllowed = item.margin;
          const discountToApply = Math.min(newDiscount, maxDiscountAllowed);
          return {
            ...item,
            discount: Math.max(0, Math.min(100, discountToApply)),
          };
        }
        return item;
      })
    );
  };

  const removeItem = (uniqueId) => {
    dispatch(removeFromCart(uniqueId));
    setSelectedItems((prev) => prev.filter((item) => item.uniqueId !== uniqueId));
  };

  const calculateChange = () => {
    return paymentAmount.cash > grandTotal
      ? paymentAmount.cash - grandTotal
      : 0;
  };

  // ------------------------- Transaction Handlers -------------------------

  const handleCancelTransaction = () => {
    setSelectedItems([]);
    setPaymentAmount({ cash: 0, card: 0, transfer: 0 });
    setTransactionStatus(null);
  };

  const generateReceiptNumber = () => {
    const date = new Date();
    const uniqueSuffix = String(date.getTime()).slice(-7);
    return `${date.getFullYear()}${date.getMonth() + 1}${date.getDate()}-${uniqueSuffix}`;
  };

  const submitTransaction = (adjustedPaymentAmount) => {
    const localDateTime = moment
      .tz(new Date(), shopTimezone)
      .format("YYYY-MM-DDTHH:mm:ss");
    const receiptNumber = generateReceiptNumber();

    const productDetails = selectedItems.map((item) => ({
      id: item.id,
      quantity: item.quantity,
      isService: item.isService,
      isPrepaid: item.isPrepaid,
      pkgId: item.pkgId || null,
      discount: item.discount,
      price_before_discount: item.price,
      unit_price: item.price - (item.price * item.discount) / 100,
      isDirect: item.isDirect,
      isAppointment: item.isAppointment,
      SelectedService: item.SelectedService,
      booking_id: item.booking_id,
      total_price:
        item.quantity * (item.price - (item.price * item.discount) / 100),
      isPromotion: isPromotionApplied,
      promotion_id: appliedPromotionId,
    }));

    const saleData = {
      date: localDateTime,
      store: shopId,
      total_sales: grandTotal,
      cash_amount: adjustedPaymentAmount.cash || 0,
      card_amount: adjustedPaymentAmount.card || 0,
      transfer_amount: adjustedPaymentAmount.transfer || 0,
      gratuity: gratuity,
      receipt_number: receiptNumber,
      products: productDetails,
      cashier: userInfo.id,
      client_id: selectedClientId || null,
    };

    axios
      .post(`${apiUrl}inventory/sales/process/`, saleData, {
        headers: { Authorization: `Bearer ${userInfo.token}` },
      })
      .then(() => {
        alert("Transaction Successful!");
        setTransactionStatus("Success");
        setTimeout(() => {
          setTransactionStatus(null);
          setSelectedItems([]);
          setPaymentAmount({ cash: 0, card: 0, transfer: 0 });
          setGratuity(0);
          setPreviouslySelectedClient(null);
          setClientSearchTerm("");
          setClientResults([]);
          setProducts([]);
        }, 2000);
      })
      .catch((error) => {
        console.error("Transaction error:", error);
        setTransactionStatus("Failed");
      })
      .finally(() => {
        setIsProcessingTransaction(false);
      });
  };

  const handleCompleteTransaction = () => {
    if (isProcessingTransaction) return;
    setIsProcessingTransaction(true);

    const totalPaid = Object.values(paymentAmount).reduce(
      (acc, amount) => acc + parseFloat(amount || 0),
      0
    );
    if (totalPaid < grandTotal) {
      alert("The amounts split does not match the grand total.");
      setIsProcessingTransaction(false);
      return;
    }
    const cashPayment = paymentAmount.cash;
    const change = cashPayment > grandTotal ? cashPayment - grandTotal : 0;
    const adjustedPaymentAmount = {
      ...paymentAmount,
      cash: cashPayment - change,
    };

    const paymentMethods = Object.keys(adjustedPaymentAmount).filter(
      (method) => adjustedPaymentAmount[method] > 0
    );
    if (paymentMethods.length === 1) {
      adjustedPaymentAmount[paymentMethods[0]] -= gratuity;
    } else {
      for (const method of paymentMethods) {
        if (adjustedPaymentAmount[method] >= gratuity) {
          adjustedPaymentAmount[method] -= gratuity;
          break;
        }
      }
    }
    if (change > 0) {
      alert(`Change to be returned: $${change.toFixed(2)}`);
    }
    selectedItems.forEach((item) => dispatch(removeFromCart(item.uniqueId)));
    submitTransaction(adjustedPaymentAmount);
  };

  const handlePriceChange = (uniqueId, newPrice) => {
    setSelectedItems((prev) =>
      prev.map((item) =>
        item.uniqueId === uniqueId
          ? { ...item, price: Math.max(0, parseFloat(newPrice) || 0) }
          : item
      )
    );
  };

  // ------------------------- Render -------------------------

  return (
    <div className="row">
      <div className="pos-container">
        {/* Product Selection Section */}
        <div className="product-selection">
          <input
            className="input-search-pos"
            type="text"
            placeholder="Search for products by name, vendor or SKU"
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <button onClick={handleSearch}>Search</button>
          <div className="product-grid">
            {products.map((product) => (
              <ProductCard
                key={product.id}
                product={product}
                onSelect={handleProductSelect}
                apiUrl={apiUrl}
              />
            ))}
          </div>
          {/* Recommendation Section */}
          {recommendations.length > 0 && (
            <div className="recommendations-section">
              <h3>You Might Also Like</h3>
              <div className="recommendation-grid">
                {recommendations.map((rec) => (
                  <ProductCard
                    key={rec.id}
                    product={rec}
                    onSelect={handleProductSelect}
                    apiUrl={apiUrl}
                  />
                ))}
              </div>
            </div>
          )}
        </div>

        {/* Transaction Summary & Client Selection */}
        <div className="transaction-summary">
          <div className="client-selection">
            <input
              className="input-search-client"
              type="text"
              placeholder="Search for client by name or email"
              value={clientSearchTerm}
              onChange={(e) => setClientSearchTerm(e.target.value)}
            />
            <button onClick={handleClientSearch}>Search Clients</button>
            <ul>
              {clientResults.map((client) => (
                <ClientResultItem
                  key={client.id}
                  client={client}
                  onSelect={handleClientSelect}
                />
              ))}
            </ul>
            <div>Client: {displaySelectedClient()}</div>
            {bookedPackages.length > 0 &&
              bookedPackages.some((pkg) => pkg.remaining_services > 0) && (
                <p className="alert alert-success mt-2">
                  100% prepaid discount will apply to up to{" "}
                  {bookedPackages.reduce(
                    (acc, pkg) => acc + pkg.remaining_services,
                    0
                  )}{" "}
                  services.
                </p>
              )}
            {promotionResponse &&
              promotionResponse.length > 0 &&
              promotionResponse.some(
                (promo) => promo.discount_percentage > 0
              ) &&
              promotionResponse.map((promo, index) => (
                <p key={index} className="alert alert-success mt-2">
                  {promo.discount_percentage}% promotion discount will apply to one service.
                </p>
              ))}
          </div>
          <h2>Cart</h2>
          <p>
            Total Items: {selectedItems.length} | Total Quantity:{" "}
            {selectedItems.reduce((acc, item) => acc + item.quantity, 0)}
          </p>
          <hr />
          {selectedItems.map((item) => (
            <CartItem
              key={item.uniqueId}
              item={item}
              onUpdateQuantity={updateQuantity}
              onUpdateDiscount={updateDiscount}
              onPriceChange={handlePriceChange}
              onRemove={removeItem}
            />
          ))}
          <hr />

          {/* Summary Section */}
          <div className="summary-section">
            <p>
              <label>Subtotal:</label>{" "}
              <span>${subtotal.toFixed(2)}</span>
            </p>
            <p>
              <label>
                Discount: <span>{calculatedDiscountPercentage.toFixed(2)}%</span>
              </label>{" "}
              <span>-${absoluteDiscount.toFixed(2)}</span>
            </p>
            <p>
              <label>Grand Total:</label>{" "}
              <span>${grandTotal.toFixed(2)}</span>
            </p>
            <div className="gratuity-section">
              <label>Gratuity:</label>
              <input
                type="number"
                value={gratuity}
                onChange={(e) =>
                  setGratuity(parseFloat(e.target.value) || 0)
                }
                placeholder="Enter gratuity amount"
              />
            </div>
          </div>
          <hr />

          {/* Payment Details Section */}
          <div className="payment-details">
            <h3>Payment Splits</h3>
            {Object.entries(paymentAmount).map(
              ([method, amount]) =>
                amount > 0 && (
                  <p key={method} className="payment-line">
                    <span className="payment-type">
                      {method.charAt(0).toUpperCase() + method.slice(1)}
                    </span>
                    <span className="payment-amount">
                      ${parseFloat(amount).toFixed(2)}
                    </span>
                  </p>
                )
            )}
            <p className="payment-total">
              <strong>Total Payments:</strong>
              <span>${totalPayments.toFixed(2)}</span>
            </p>
            <p>
              {totalPayments.toFixed(2) === grandTotal.toFixed(2)
                ? "Matches Grand Total"
                : paymentAmount.cash > grandTotal
                ? `Change to be returned is: $${calculateChange().toFixed(2)}`
                : "Does not match Grand Total"}
            </p>
          </div>
          <hr />

          {["cash", "card", "transfer"].map((method) =>
            showPaymentInputs[method] ? (
              <PaymentMethodInput
                key={method}
                method={method}
                amount={paymentAmount[method]}
                onAmountChange={handlePaymentAmountChange}
                onSetRemaining={handleSetRemainingAmount}
                onSetFull={handleSetFullAmount}
              />
            ) : null
          )}

          <div className="payment-methods">
            {["cash", "card", "transfer"].map((method) => (
              <div key={method} className="payment-method-button">
                <button
                  onClick={() =>
                    setShowPaymentInputs({
                      cash: false,
                      card: false,
                      transfer: false,
                      [method]: true,
                    })
                  }
                >
                  {method.charAt(0).toUpperCase() + method.slice(1)}
                </button>
              </div>
            ))}
          </div>

          <div className="complete-cancel-buttons-container">
            <button
              className={`complete-transaction-button ${
                isProcessingTransaction ? "disabled-button" : ""
              }`}
              style={{
                backgroundColor: isProcessingTransaction ? "#ccc" : "#28a745",
                color: isProcessingTransaction ? "#666" : "#fff",
                cursor: isProcessingTransaction ? "not-allowed" : "pointer",
              }}
              onClick={handleCompleteTransaction}
              disabled={isProcessingTransaction}
            >
              Finalize Sale
            </button>
            <button
              className="cancel-transaction-button"
              onClick={handleCancelTransaction}
            >
              Cancel
            </button>
            {transactionStatus && (
              <p>Transaction Status: {transactionStatus}</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PosSystem;

