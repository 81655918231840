import React, { useState, useEffect, useCallback } from "react";
import { debounce } from "lodash";
import { Table, Modal, Button, Spinner, Alert } from "react-bootstrap";
import Sidebar from "../Design/TodayBookingSidebar.js";
import ServicesForm from "./ServicesForm";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";


const AllServices = () => {
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const dispatch = useDispatch();
  const shop = useSelector((state) => state.shop);
  const id = shop.shopId;
  const shopName = shop.shopName;

  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  // For modal handling: null for add, or a service object for edit.
  const [selectedService, setSelectedService] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const fetchServices = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios
        .get(`${apiUrl}inventory/inventory-services/`)
        .then((response) => setServices(response.data))
        .catch((error) => console.error("Error fetching services", error));
        setServices(response.data);
    } catch (err) {
      console.error("Error fetching services", err);
      setError("Error fetching services");
    } finally {
      setLoading(false);
    }
  }, [apiUrl]);

  useEffect(() => {
    fetchServices();
  }, [fetchServices]);

  // Debounced search function to limit API calls
  const debouncedSearch = useCallback(
    debounce(async (query) => {
      setLoading(true);
      setError(null);
      try {
        const response = await axios.get(
          `${apiUrl}inventory/inventory-services/search/?query=${query}`
        );
        setServices(response.data);
      } catch (err) {
        console.error("Error fetching services", err);
        setError("Error fetching services");
      } finally {
        setLoading(false);
      }
    }, 500),
    [apiUrl]
  );

  // Update search term and trigger debounced search
  const handleSearchInputChange = useCallback(
    (e) => {
      const value = e.target.value;
      setSearchTerm(value);
      debouncedSearch(value);
    },
    [debouncedSearch]
  );

  // Open the modal; if a service is provided, it's edit mode
  const handleOpenModal = useCallback((service = null) => {
    setSelectedService(service);
    setShowModal(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setShowModal(false);
  }, []);

  // Delete service handler

  const handleDeleteService = useCallback(
    async (serviceId) => {
      if (window.confirm("Are you sure you want to delete this service?")) {
        try {
          await axios.delete(`${apiUrl}inventory/inventory-services/${serviceId}/`);
          setServices((prevServices) =>
            prevServices.filter((service) => service.id !== serviceId)
          );
        } catch (err) {
          console.error("Error deleting service", err);
          setError("Error deleting service");
        }
      }
    },
    [apiUrl]
  );

  // Callback for when a service is added or updated successfully
  const handleServiceSaved = useCallback(
    (savedService) => {
      setServices((prevServices) => {
        const index = prevServices.findIndex((s) => s.id === savedService.id);
        if (index !== -1) {
          // Update existing service
          const updatedServices = [...prevServices];
          updatedServices[index] = savedService;
          return updatedServices;
        } else {
          // Add new service
          return [...prevServices, savedService];
        }
      });
      handleCloseModal();
    },
    [handleCloseModal]
  );

  return (
    <div className="row">
      <div className="col-md-3" style={{ paddingRight: "0px" }}>
        <Sidebar id={id} shopName={shopName} />
      </div>
      <div className="col-md-9" style={{ paddingLeft: "0px" }}>
        <h3
          style={{ paddingBottom: "10px", fontWeight: "700", color: "#282c34" }}
        >
          Services
        </h3>

        <div>
          <Button onClick={() => handleOpenModal()} className="mt-2 mb-4 mr-2">
            Add New Service
          </Button>
        </div>

        <div className="search-filter">
          <input
            type="text"
            placeholder="Search for services"
            value={searchTerm}
            onChange={handleSearchInputChange}
          />
        </div>

        {loading && <Spinner animation="border" />}
        {error && <Alert variant="danger">{error}</Alert>}

        <Table striped bordered hover className="m-2">
          <thead>
            <tr>
              <th>Name</th>
              <th>Category</th>
              <th>Price</th>
              <th style={{ textAlign: "center" }}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {services.map((service) => (
              <tr key={service.id}>
                <td>{service.name}</td>
                <td>{service.category}</td>
                <td>${service.price}</td>
                <td style={{ textAlign: "center" }}>
                  <Button
                    variant="primary"
                    className="mr-2"
                    onClick={() => handleOpenModal(service)}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="danger"
                    onClick={() => handleDeleteService(service.id)}
                  >
                    Delete
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        dialogClassName="custom-modal-width"
      >
        <Modal.Header closeButton>
          <Modal.Title>{selectedService ? "Edit Service" : "Add Service"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ServicesForm
            service={selectedService}
            onServiceSaved={handleServiceSaved}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AllServices;
